<!-- 模块说明 -->
<template>
  <div class="container">
    <subHead :active="4" :subpageActive="activeName"></subHead>
    <div class="banner">
      <div class="title">
        <h5>关于海恒</h5>
        <p>About Haiheng</p>
      </div>
    </div>
    <div class="tabs">
      <div class="content">
        <div
          class="typeList"
          :class="{ on: activeName == index }"
          v-for="(item, index) in typeList"
          :key="index"
          @click="activeName = index"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <template v-if="activeName == 0">
      <div class="modular modular-1">
        <div class="title-on">
          <div class="t1">集团简介</div>
          <div class="t2">Group Profile</div>
          <div class="t3"></div>
        </div>
        <div class="content flex">
          <img src="../../images/home/about/p14.png" alt="集团简介" />
          <div class="right">
            <div class="text">
              海恒科技集团致力于成为全球劳动力综合解决方案提供商，品牌布局为“一个平台，三张名片”,“三张名片”分别是海纳明人、上职家、晟工数智，为政企提供劳务服务、人才服务、技术服务。
            </div>
            <div class="text">
              海恒拥有500+专业服务团队、100万+人才资源、5000+企业资源等资源，累计为30万人次劳动者、20+细分行业的500余家用人单位提供了灵活用工、招聘服务、高端猎聘、培训咨询等人力资源服务。总部设在长沙，在华南、华东的20余个城市设立分子公司。
            </div>
            <div class="text">
              未来，海恒将继续通过服务、产品和科技创造价值，构建数智人力资源生态。
            </div>
          </div>
        </div>
      </div>
      <div class="modular modular-2">
        <div class="title-on">
          <div class="t1">集团文化</div>
          <div class="t2">Culture</div>
          <div class="t3"></div>
        </div>
        <div class="content flex">
          <div class="view">
            <img class="back" src="../../images/home/about/p16.png" />
            <div class="tips">
              <img src="../../images/home/about/p7.png" />
              <div>
                <div class="name">使命</div>
                <div class="text">通过技术革新构建人力资源生态</div>
              </div>
            </div>
          </div>
          <div class="view">
            <img class="back" src="../../images/home/about/p15.png" />
            <div class="tips">
              <img src="../../images/home/about/p4.png" />
              <div>
                <div class="name">价值观</div>
                <div class="text">
                  成就客户 精心工作 诚信自律 锲而不舍 开拓进取
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modular modular-3">
        <div class="title-on">
          <div class="t1">荣誉资质</div>
          <div class="t2">Honorary qualifications</div>
          <div class="t3"></div>
        </div>
        <div class="content flex">
          <img src="../../images/home/about/p9.png" />
          <img src="../../images/home/about/p10.png" />
          <img src="../../images/home/about/p11.png" />
          <img src="../../images/home/about/p12.png" />
          <img src="../../images/home/about/p13.png" />
        </div>
      </div>
      <div class="modular modular-4">
        <div class="content flex">
          <i @click="reduce" class="el-icon-arrow-left"></i>
          <div class="all-view">
            <div class="list" id="list">
              <div
                class="view flex"
                v-for="(item, index) in imglist"
                :key="index"
              >
                <img
                  v-for="(l, i) in item"
                  :key="i"
                  :src="require('../../images/home/about/zs/' + l + '.png')"
                />
              </div>
            </div>
          </div>
          <i @click="add" class="el-icon-arrow-right"></i>
        </div>
        <div class="js">以上仅展示部分内容，数据统计对象包括集团及其相关公司</div>
      </div>
      <div class="modular modular-5">
        <div class="title-on">
          <div class="t1">发展历程</div>
          <div class="t2">development history</div>
          <div class="t3"></div>
        </div>
        <div id="itemShow" class="item-view">
          <div v-for="(row, i) in courseList" :key="i" class="list">
            <div class="top">
              <div class="title">{{ row.name ? row.name : row.time }}</div>
              <div
                :style="row.name ? 'opacity: 1;' : 'opacity: 0;'"
                class="time"
              >
                {{ row.time }}
              </div>
            </div>
            <img src="../../images/home/about/p8.png" />
            <div class="profile">
              <div v-for="(txt, l) in row.synopsis" :key="l" class="text">
                <div class="i"></div>
                {{ txt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="activeName == 1">
      <div class="modular modular-6">
        <div class="content">
          <img src="../../images/home/about/p6.png" />
        </div>
      </div>
    </template>
    <template v-if="activeName == 2">
      <div class="modular modular-7">
        <div class="title-on">
          <div class="t1">联系我们</div>
          <div class="t2">Contact Us</div>
          <div class="t3"></div>
        </div>
        <div class="content flex">
          <div class="view left">
            <div class="hezi">
              <div class="title">
                <img src="../../images/home/about/p1.png" />
                全国服务热线
              </div>
              <div class="call">400 0550 766</div>
            </div>
            <img class="back" src="../../images/home/about/p2.png" />
          </div>
          <div class="view right">
            <div class="hezi">
              <div class="title">
                <img src="../../images/home/about/p5.png" />
                联系地址
              </div>
              <div class="text">
                总部地址：湖南省长沙市经开区东六路科技新城C2栋19楼.15楼
              </div>
              <div class="text">
                华东公司：苏州市工业园区江韵路仙峰大厦南楼5楼
              </div>
              <div class="text">
                华南公司：广州市海珠区敦和路189号海珠科技园2栋3楼
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import subHead from "../../components/subHead.vue";
export default {
  components: { subHead },
  props: [],
  data() {
    return {
      typeList: [
        {
          name: "集团概况",
        },
        {
          name: "社会责任",
        },
        {
          name: "联系我们",
        },
      ],
      activeName: 0,
      imglist: [
        [1, 2, 3, 4],
        [5, 6, 7, 8],
        [9, 10, 11, 12],
        [13, 14, 15, 16],
        [17, 18, 19, 20],
        [21, 22, 23, 24],
      ],
      distance: 0,
      courseList: [
        {
          name: "筹备阶段",
          time: "2010-2013",
          synopsis: [
            "集团创始人郭泳辰成立湖南大学创业团队，与各大人力资源服务公司达成战略合作，迈步进入人力资源服务行业",
          ],
        },
        {
          name: "成长阶段",
          time: "2014-2018",
          synopsis: [
            "创立海纳明人品牌，正式进入人力资源服务行业",
            "与蓝思科技、三一重工、京东物流等数百家企业达成合作",
            "与湖南农大等十余所院校建立了校企合作",
          ],
        },
        {
          name: "转型阶段",
          time: "2019",
          synopsis: [
            "2019成立湖南海润天恒信息科技有限公司，创立上职家品牌，公司开始数字化转型",
            "2019总部落户长沙科技新城",
          ],
        },
        {
          name: null,
          time: "2020",
          synopsis: ["2020布局20余家分子公司，扩大业务范围"],
        },
        {
          name: null,
          time: "2021",
          synopsis: [
            "2021上职家用户超过10万",
            "2021海润天恒获评国家高新技术企业",
          ],
        },
        {
          name: null,
          time: "2022",
          synopsis: [
            "2022成立华东分公司，创立晟工数智品牌，全面进军数字化人力资源服务",
          ],
        },
        {
          name: null,
          time: "2023",
          synopsis: [
            "2023成立华南分公司，不断扩张事业版图",
            "2023数字员工上线",
          ],
        },
      ],
    };
  },
  watch: {
    "$route.query.show": {
      handler(newVal) {
        this.activeName = +newVal || 0;
      },
      deep: true,
    },
  },
  created() {
    this.activeName = +this.$route.query.show;
  },
  methods: {
    add() {
      this.distance++;
      if (this.distance > 5) {
        this.distance = 5;
        return;
      }
      document.getElementById("list").style.marginLeft =
        this.distance * 1040 * -1 + "px";
    },
    reduce() {
      this.distance--;
      if (this.distance < 0) {
        this.distance = 0;
        return;
      }
      document.getElementById("list").style.marginLeft =
        this.distance * 1040 * -1 + "px";
    },
  },
  mounted() {
    let id = document.getElementById("itemShow");
    id.scrollLeft = id.scrollHeight*6;
  },
};
</script>

<style lang="less" scoped>
.modular-7 {
  .content {
    .view {
      .hezi {
        .text {
          font-size: 18px;
          color: #333333;
          margin-bottom: 12px;
        }
        .call {
          font-size: 36px;
          color: #166dba;
        }
        .title {
          img {
            width: 30px;
            height: 30px;
            margin-right: 14px;
          }
          font-size: 24p;
          color: #166dba;
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }
        position: absolute;
        top: 31px;
        left: 40px;
      }
      .back {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 313px;
        height: 197px;
      }
      width: 580px;
      height: 235px;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      position: relative;
    }
    margin-bottom: 200px;
  }
}
.modular-6 {
  img:hover {
    transform: scale(1.05);
    transition: all 5s;
  }
  img {
    width: 1200px;
    height: 616px;
  }
  margin: 83px 0;
}
.modular-5 {
  .item-view {
    .list {
      .profile {
        .text {
          .i {
            width: 4px;
            height: 4px;
            background: #166dba;
            margin-top: 8px;
            flex-shrink: 0;
            margin-right: 9px;
          }
          display: flex;
          font-size: 14px;
          color: #666666;
          margin: 10px;
        }
        padding: 20px 10px;
        width: 350px;
        background: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
      }
      img {
        width: 28px;
        height: 92px;
        margin: 34px 0 17px;
      }
      .time {
        font-size: 20px;
      }
      .title {
        font-size: 26px;
        margin-bottom: 15px;
      }
      .top {
        color: white;
        font-weight: bold;
      }
      display: flex;
      flex-direction: column;
      margin: 0 40px;
    }
    width: 70%;
    display: flex;
    margin: auto;
    overflow-x: auto;
    padding-bottom: 70px;
  }
  /*滚动条样式*/
  .item-view::-webkit-scrollbar {
    height: 8px !important;
  }
  .item-view::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #0088fe;
  }
  .item-view::-webkit-scrollbar-track {
    border-radius: 6px;
    background: #f2faff;
  }
  .title-on {
    .t1 {
      color: white !important;
    }
    .t2 {
      color: white !important;
    }
    margin: 50px auto 63px !important;
  }
  height: 760px;
  background: url("../../images/home/about/p17.png") no-repeat;
  background-size: cover;
  position: relative;
  margin: 50px auto 160px;
  padding: 0 !important;
  overflow: hidden;
}
.modular-4 {
  .js {
    font-size: 14px;
    text-align: center;
    color: #666666;
    margin-top: 43px;
  }
  .list {
    .view {
      img {
        width: 235px;
        height: 332px;
        margin: 0 14px;
      }
      width: 1040px;
      height: 332px;
    }
    display: flex;
  }
  .all-view {
    width: 1040px;
    height: 332px;
    overflow: hidden;
  }
  i {
    font-size: 30px;
    border: 1px solid #aaaaaa;
    color: #aaaaaa;
    padding: 10px;
    cursor: pointer;
  }
  i:hover {
    border: 1px solid #166dba;
    color: #166dba;
  }
  padding: 0 0 50px !important;
}
.modular-3 {
  .content {
    img {
      width: 166px;
      height: 155px;
    }
    width: 1054px;
    margin: 60px auto 20px;
  }
}
.modular-2 {
  .view {
    .tips {
      .text {
        font-size: 18px;
      }
      .name {
        font-size: 32px;
        margin-bottom: 10px;
      }
      img {
        width: 74px;
        height: 74px;
        margin-right: 35px;
      }
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      position: absolute;
      top: 42px;
      left: 36px;
    }
    .back {
      width: 550px;
      height: 160px;
    }
    width: 550px;
    height: 160px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .content {
    width: 1140px;
  }
}
.modular-1 {
  .text {
    font-size: 16px;
    color: #333333;
    padding-bottom: 40px;
    text-indent: 40px;
    line-height: 30px;
  }
  img {
    width: 545px;
    height: 411px;
    margin-right: 63px;
    flex-shrink: 0;
  }
}
.modular {
  .title-on {
    .t3 {
      width: 30px;
      height: 4px;
      background: #cae6ff;
      margin: auto;
    }
    .t2 {
      font-size: 16px;
      color: #dddddd;
      margin: 10px 0 15px;
    }
    .t1 {
      font-size: 24px;
      color: #333333;
      font-weight: bold;
    }
    text-align: center;
    margin-bottom: 40px;
  }
  padding: 50px 0;
  width: 100%;
  background-position: center center;
}
.tabs {
  .typeList {
    padding: 27px 0;
    font-size: 16px;
    color: #666666;
    margin-right: 58px;
    cursor: pointer;
  }
  .on {
    color: #166dba;
    font-weight: 600;
    border-bottom: 2px solid #166dba;
  }
  .content {
    display: flex;
  }
  background-color: #fbfbfb;
}
.banner {
  .title {
    position: absolute;
    top: 50%;
    left: 26%;
    transform: translate(-50%, -50%);
    p {
      font-size: 24px;
    }
    h5 {
      font-size: 48px;
      margin-bottom: 10px;
    }
    color: white;
  }
  width: 100%;
  height: 460px;
  background: url("../../images/home/about/p3.png") no-repeat;
  background-size: cover;
  background-position-x: 50%;
  position: relative;
}
.content {
  width: 1200px;
  margin: auto;
}
.container {
  overflow: hidden;
  width: 100%;
}
</style>
