var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('subHead',{attrs:{"active":4,"subpageActive":_vm.activeName}}),_vm._m(0),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"content"},_vm._l((_vm.typeList),function(item,index){return _c('div',{key:index,staticClass:"typeList",class:{ on: _vm.activeName == index },on:{"click":function($event){_vm.activeName = index}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),(_vm.activeName == 0)?[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"modular modular-4"},[_c('div',{staticClass:"content flex"},[_c('i',{staticClass:"el-icon-arrow-left",on:{"click":_vm.reduce}}),_c('div',{staticClass:"all-view"},[_c('div',{staticClass:"list",attrs:{"id":"list"}},_vm._l((_vm.imglist),function(item,index){return _c('div',{key:index,staticClass:"view flex"},_vm._l((item),function(l,i){return _c('img',{key:i,attrs:{"src":require('../../images/home/about/zs/' + l + '.png')}})}),0)}),0)]),_c('i',{staticClass:"el-icon-arrow-right",on:{"click":_vm.add}})]),_c('div',{staticClass:"js"},[_vm._v("以上仅展示部分内容，数据统计对象包括集团及其相关公司")])]),_c('div',{staticClass:"modular modular-5"},[_vm._m(4),_c('div',{staticClass:"item-view",attrs:{"id":"itemShow"}},_vm._l((_vm.courseList),function(row,i){return _c('div',{key:i,staticClass:"list"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(row.name ? row.name : row.time))]),_c('div',{staticClass:"time",style:(row.name ? 'opacity: 1;' : 'opacity: 0;')},[_vm._v(" "+_vm._s(row.time)+" ")])]),_c('img',{attrs:{"src":require("../../images/home/about/p8.png")}}),_c('div',{staticClass:"profile"},_vm._l((row.synopsis),function(txt,l){return _c('div',{key:l,staticClass:"text"},[_c('div',{staticClass:"i"}),_vm._v(" "+_vm._s(txt)+" ")])}),0)])}),0)])]:_vm._e(),(_vm.activeName == 1)?[_vm._m(5)]:_vm._e(),(_vm.activeName == 2)?[_vm._m(6)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"title"},[_c('h5',[_vm._v("关于海恒")]),_c('p',[_vm._v("About Haiheng")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modular modular-1"},[_c('div',{staticClass:"title-on"},[_c('div',{staticClass:"t1"},[_vm._v("集团简介")]),_c('div',{staticClass:"t2"},[_vm._v("Group Profile")]),_c('div',{staticClass:"t3"})]),_c('div',{staticClass:"content flex"},[_c('img',{attrs:{"src":require("../../images/home/about/p14.png"),"alt":"集团简介"}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"text"},[_vm._v(" 海恒科技集团致力于成为全球劳动力综合解决方案提供商，品牌布局为“一个平台，三张名片”,“三张名片”分别是海纳明人、上职家、晟工数智，为政企提供劳务服务、人才服务、技术服务。 ")]),_c('div',{staticClass:"text"},[_vm._v(" 海恒拥有500+专业服务团队、100万+人才资源、5000+企业资源等资源，累计为30万人次劳动者、20+细分行业的500余家用人单位提供了灵活用工、招聘服务、高端猎聘、培训咨询等人力资源服务。总部设在长沙，在华南、华东的20余个城市设立分子公司。 ")]),_c('div',{staticClass:"text"},[_vm._v(" 未来，海恒将继续通过服务、产品和科技创造价值，构建数智人力资源生态。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modular modular-2"},[_c('div',{staticClass:"title-on"},[_c('div',{staticClass:"t1"},[_vm._v("集团文化")]),_c('div',{staticClass:"t2"},[_vm._v("Culture")]),_c('div',{staticClass:"t3"})]),_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"view"},[_c('img',{staticClass:"back",attrs:{"src":require("../../images/home/about/p16.png")}}),_c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("../../images/home/about/p7.png")}}),_c('div',[_c('div',{staticClass:"name"},[_vm._v("使命")]),_c('div',{staticClass:"text"},[_vm._v("通过技术革新构建人力资源生态")])])])]),_c('div',{staticClass:"view"},[_c('img',{staticClass:"back",attrs:{"src":require("../../images/home/about/p15.png")}}),_c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("../../images/home/about/p4.png")}}),_c('div',[_c('div',{staticClass:"name"},[_vm._v("价值观")]),_c('div',{staticClass:"text"},[_vm._v(" 成就客户 精心工作 诚信自律 锲而不舍 开拓进取 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modular modular-3"},[_c('div',{staticClass:"title-on"},[_c('div',{staticClass:"t1"},[_vm._v("荣誉资质")]),_c('div',{staticClass:"t2"},[_vm._v("Honorary qualifications")]),_c('div',{staticClass:"t3"})]),_c('div',{staticClass:"content flex"},[_c('img',{attrs:{"src":require("../../images/home/about/p9.png")}}),_c('img',{attrs:{"src":require("../../images/home/about/p10.png")}}),_c('img',{attrs:{"src":require("../../images/home/about/p11.png")}}),_c('img',{attrs:{"src":require("../../images/home/about/p12.png")}}),_c('img',{attrs:{"src":require("../../images/home/about/p13.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-on"},[_c('div',{staticClass:"t1"},[_vm._v("发展历程")]),_c('div',{staticClass:"t2"},[_vm._v("development history")]),_c('div',{staticClass:"t3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modular modular-6"},[_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../images/home/about/p6.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modular modular-7"},[_c('div',{staticClass:"title-on"},[_c('div',{staticClass:"t1"},[_vm._v("联系我们")]),_c('div',{staticClass:"t2"},[_vm._v("Contact Us")]),_c('div',{staticClass:"t3"})]),_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"view left"},[_c('div',{staticClass:"hezi"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../images/home/about/p1.png")}}),_vm._v(" 全国服务热线 ")]),_c('div',{staticClass:"call"},[_vm._v("400 0550 766")])]),_c('img',{staticClass:"back",attrs:{"src":require("../../images/home/about/p2.png")}})]),_c('div',{staticClass:"view right"},[_c('div',{staticClass:"hezi"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../../images/home/about/p5.png")}}),_vm._v(" 联系地址 ")]),_c('div',{staticClass:"text"},[_vm._v(" 总部地址：湖南省长沙市经开区东六路科技新城C2栋19楼.15楼 ")]),_c('div',{staticClass:"text"},[_vm._v(" 华东公司：苏州市工业园区江韵路仙峰大厦南楼5楼 ")]),_c('div',{staticClass:"text"},[_vm._v(" 华南公司：广州市海珠区敦和路189号海珠科技园2栋3楼 ")])])])])])
}]

export { render, staticRenderFns }